<div class="container">
  <div nz-row [nzJustify]="'center'" class="construction">
    🚧 &nbsp; Nuestra app está en <br />
    construcción, por lo que no es la <br />
    versión final. &nbsp; 🚧
  </div>
  <form nz-form [formGroup]="form" class="login-form" (ngSubmit)="submitForm()">
    <div nz-row [nzJustify]="'center'">
      <div nz-col nzXs="18" nzMd="12" nzLg="8" nzXl="6">
        <nz-form-item>
          <nz-form-control
            nzErrorTip="Nombre de usuario incorrecto, inténtalo denuevo"
          >
            <nz-input-group nzPrefixIcon="user">
              <input
                type="text"
                nz-input
                formControlName="userName"
                placeholder="Usuario"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row [nzJustify]="'center'">
      <div nz-col nzXs="18" nzMd="12" nzLg="8" nzXl="6">
        <nz-form-item>
          <nz-form-control
            nzErrorTip="Contraseña incorrecta, inténtalo denuevo!"
          >
            <nz-input-group nzPrefixIcon="lock">
              <input
                [type]="passwodInputType"
                nz-input
                formControlName="password"
                placeholder="Contraseña"
              />
              <i
                (click)="togglePasswordVisibility()"
                *ngIf="isPasswordVisible"
                nz-icon
                nzType="eye"
                nzTheme="outline"
              ></i>
              <i
                (click)="togglePasswordVisibility()"
                *ngIf="!isPasswordVisible"
                nz-icon
                nzType="eye-invisible"
                nzTheme="outline"
              ></i>
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row [nzJustify]="'center'">
      <div nz-col nzXs="18" nzMd="12" nzLg="8" nzXl="6">
        <button
          nz-button
          nzBlock
          class="login-form-button login-form-margin"
          [nzType]="'primary'"
          nzShape="round"
        >
          Siguiente
        </button>
      </div>
    </div>
  </form>

  <div nz-row style="margin-top: 3rem">
    <div nz-col>
      <p>
        AccessToken:
        {{ params.accessToken }}
      </p>
    </div>
  </div>

  <div nz-row style="margin-top: 3rem">
    <div nz-col>
      <p>
        PKCE:
        {{ params.pkce }}
      </p>
    </div>
  </div>
</div>
