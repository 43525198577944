import { Injectable } from '@angular/core';
import { IAccountInfo } from '../typings';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private data: IAccountInfo[] = [
    {
      name: 'Día a día',
      number: '01-02-03-456789-0',
      funds: '$100.00',
      checked: true,
    },
    {
      name: 'Planilla',
      number: '04-03-02-456789-0',
      funds: '$500.00',
      checked: false,
    },
    {
      name: 'Viajes',
      number: '04-03-02-456789-0',
      funds: '$300.00',
      checked: false,
    },
  ];

  constructor() {}

  public fetchAccounts(): Promise<IAccountInfo[]> {
    return Promise.resolve(this.data);
  }
}
