import { Component, OnInit } from '@angular/core';
import { IAccountInfo } from '../../typings';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-accounts-page',
  templateUrl: './accounts-page.component.html',
  styleUrls: ['./accounts-page.component.scss'],
})
export class AccountsPageComponent implements OnInit {
  isLoading = false;
  selectedAccount: IAccountInfo;
  isVisible = false;

  data: IAccountInfo[] = [];

  constructor(private authenticationService: AuthenticationService) {
    this.fetchAccounts();
  }

  public ngOnInit(): void {}

  public handleSelect(i: number) {
    this.data[i].checked = !this.data[i].checked;

    this.data.forEach((_, idx) => {
      if (idx !== i) {
        this.data[idx].checked = false;
      }
    });
  }

  public async fetchAccounts() {
    this.data = await this.authenticationService.fetchAccounts();
  }

  public showModal(): void {
    this.isVisible = true;
  }

  public handleOk(): void {
    this.isVisible = false;
  }

  public handleCancel(): void {
    this.isVisible = false;
  }

  public submit(): void {
    this.selectedAccount = this.data.filter(
      (account) => account.checked !== false
    )[0];

    this.showModal();
    const message = {
      code: 'Error',
      account: this.selectedAccount,
    };
    (window as any).webkit.messageHandlers.cordova_iab.postMessage(
      JSON.stringify(message)
    );

    setTimeout(() => {
      this.handleCancel();
      window.location.href = 'https://oauth.yappycloud.com?code=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Im15LWtleS1pZCJ9.eyJhY3Rpb24iOiJJIiwiaWF0IjoxNjI3NjU3NDMxLCJleHAiOjE2Mjc2NjQ2MzEsI';
    }, 2000);
  }
}
