import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  public form: FormGroup;
  public isPasswordVisible = false;
  public passwodInputType = 'password';
  public params = {
    accessToken: '',
    pkce: '',
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      console.log(params);
      this.params.accessToken = params['otp'];
      this.params.pkce = params['pkce'];
    });
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      userName: ['usuario', [Validators.required]],
      password: ['123456', [Validators.required]],
    });
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
    this.passwodInputType = this.isPasswordVisible ? 'text' : 'password';
  }

  submitForm(): void {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }

    this.router.navigateByUrl('/accounts');
  }
}
