<div class="container">
  <h1>¿Qué cuenta deseas vincular a Yappy?</h1>
  <nz-list nzItemLayout="horizontal" [nzLoading]="isLoading">
    <nz-list-item
      class="accounts-info"
      *ngFor="let item of data; let i = index"
    >
      <div nz-row nzAlign="middle">
        <div nz-col nzSpan="4" class="">
          <input
            class="checkbox"
            type="checkbox"
            id="i"
            (click)="handleSelect(i)"
            [checked]="item.checked"
          />
        </div>

        <div nz-col nzSpan="20">
          <div nz-row>
            <div nz-col nzSpan="24" class="accounts-info__name">
              {{ item.name }}
            </div>
            <div nz-col nzSpan="24" class="accounts-info__number">
              {{ item.number }}
            </div>
            <div nz-col nzSpan="24" class="accounts-info__funds">
              {{ item.funds }}
            </div>
          </div>
        </div>
      </div>
    </nz-list-item>

    <nz-list-empty *ngIf="data.length === 0"></nz-list-empty>
  </nz-list>

  <div nz-row [nzJustify]="'center'">
    <div nz-col nzXs="24" nzMd="8">
      <button
        (click)="submit()"
        class="btn"
        nz-button
        nzBlock
        nzType="primary"
        nzShape="round"
      >
        Confirmar
      </button>
    </div>
  </div>
</div>

<nz-modal
  [nzClosable]="false"
  (nzOnCancel)="handleCancel()"
  [nzFooter]="null"
  [(nzVisible)]="isVisible"
  nzCentered
>
  <ng-container class="modal-content" *nzModalContent>
    <div nz-row>
      <div nz-col nzSpan="4">
        <i nz-icon nzType="loading" class="loading-icon" nzTheme="outline"></i>
      </div>
      <div nz-col nzSpan="20">
        <p class="message">Guardando...</p>
      </div>
    </div>
  </ng-container>
</nz-modal>
